<template>
    <div class="ConfirmedListMobile">
        <div class="header">
            <div class="inner_wrap">
                <h1 style="font-size: 14px;"><span>서울시 코로나 확진자 관리시스템</span></h1>
            </div>
            <div class="utilArea" v-if="userDataAdminId" style="position: absolute; right: 10px; top: 8px;z-index: 1000">
                <div class="memberUser">
                    <a class="btn2" title="로그아웃" @click="logOutFunc">로그아웃</a>
                </div>
            </div>
        </div>
        <div id="contentsWrap">
            <div class="container sub">
                <div class="row no-pdd-top">
                    <div class="subHead">
                        <h3># {{ statusData.f_fasility_name }}
                            <span style="color: darkred; font-size: 12px;">병상현황</span>
                            <div class="mrg-top-10 mrg-btm-10">
                                <a class="btn10 width-100" @click="popupConditionFunc()">수정하기</a> &nbsp;
                            </div>
                        </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="25%">
                            <col width="25%">
                            <col width="25%">
                            <col width="25%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">총병상</th>
                            <th class="field">사용중</th>
                            <th class="field">입실불가능</th>
                            <th class="field">입실가능</th>
                        </tr>
                        <tr>
                            <td class="field text-center">{{ statusData.f_total_room | comma }} 명</td>
                            <td class="field text-center">{{ statusData.f_use_room | comma }} 명</td>
                            <td class="field text-center">{{ statusData.f_imposible_room | comma }} 명</td>
                            <td class="field text-center">{{ statusData.f_unused_room | comma }} 명</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="subHead">
                        <h3># 시설 확진자 목록</h3>
                    </div>
                    <div class="subBody mrg-btm-50" v-if="dataList.length>0">
                        <ul class="confirmed-list">
                            <li v-for="(data, index) in dataList" :key="index">
                                <div class="row no-pdd-top">
                                    <h5>
                                        {{
                                            data.f_patient_name + '('+ data.f_patient_age +')' + ' / ' + ((data.f_patient_birth != '0000-00-00 00:00:00') ? $moment(data.f_patient_birth).format('YYYY-MM-DD') : '') + '('+ data.f_patient_sex +')'
                                        }}
                                        <div class="pull-right" v-if="data.f_assign_state_code !='G0303'">
                                            <a class="btn10" @click="confirmedEnterFunc(data)">입실완료</a> &nbsp;
                                        </div>
                                    </h5>
                                </div>
                                <div class="row pdd-top-10">
                                    <h6>
                                        <ul class="confirmed-detail">
                                            <li>▶ 지역 : {{ data.f_region }}</li>
                                            <li>▶ 확진일 : {{ (data.f_confirm_date != '0000-00-00 00:00:00') ? $moment(data.f_confirm_date).format('YYYY-MM-DD') : '' }}</li>
                                            <li>▶ 배정일 : {{ (data.f_assign_date != '0000-00-00 00:00:00') ? $moment(data.f_assign_date).format('YYYY-MM-DD') : '' }}</li>
                                        </ul>
                                    </h6>
                                </div>
                                <div class="row pdd-top-10">
                                    <h6 class="mobile-sm-div">
                                        ▶ 중증도 : {{ data.f_symptom_level }}
                                        <!--                                        <div class="pull-right">-->
                                        <!--                                            <a class="btn8 btn-sm" @click="popupMobileFunc(data, list_symptom_lv, 'G0908')">증상변경</a> &nbsp;-->
                                        <!--                                        </div>-->
                                    </h6>
                                </div>
                                <div class="row pdd-top-5">
                                    <h6 class="mobile-sm-div">
                                        ▶ 중증상태 : {{ data.f_symptom_type }}
                                        <!--                                        <div class="pull-right">-->
                                        <!--                                            <a class="btn8 btn-sm" @click="popupMobileFunc(data, list_symptom_state, 'G0910')">상태변경</a> &nbsp;-->
                                        <!--                                        </div>-->
                                    </h6>
                                </div>
                                <div class="row text-center pdd-top-20">
                                    <a class="btn btn-sm btn-danger" @click="popupUnderlyingDiseaseFunc(data)">기저질환등록</a>
                                    <a class="btn btn-sm btn-inverse" @click="popupConfirmedTransferredFunc(data)">전원등록</a>
                                    <a class="btn btn-sm btn-info" @click="popupSeverityFunc(data)">중증도 변경</a>
                                    <a class="btn btn-sm btn-primary" @click="confirmedExitFunc(data)">퇴소/퇴원</a>
                                </div>
                            </li>
                        </ul>
                        <div class="text-center">
                            <paginate v-model="page" :page-count="totalPage" :prev-text="'Prev'" :next-text="'Next'" :containerClass="'pagination'" :click-handler="changePage"></paginate>
                        </div>
                    </div>
                    <div class="subBody" v-else>
                        <ul class="confirmed-list">
                            <li>
                                <div class="row no-pdd-top text-center">
                                    <h2>검색결과가 존재 하지 않습니다.</h2>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-search">
            <input type="text" class="input type2 width-100" v-model="sch_keyword" />
            <div class="btn-search-area">
                <a class="btn7" @click="searchFunc">조회</a>
            </div>
        </div>

        <!-- 병상현황 팝업 -->
        <popup-condition ref="popupCondition" />

        <!-- 증상변경, 상태변경, 팝업 -->
        <popup-mobile-combo ref="popupMobileCombo" />

        <!-- 기절질환 팝업 -->
        <popup-underlying-disease ref="popupUnderlyingDisease" />

        <!-- 전원등록 팝업 -->
        <popup-confirmed-transferred ref="popupConfirmedTransferred" />

        <!-- 전원등록 팝업 -->
        <popup-severity ref="popupSeverity" />

        <!-- 퇴원/퇴실 팝업 -->
        <popup-confirmed-exit ref="popupConfirmedExit" />
    </div>
</template>

<script>
import popupCondition from '@/components/PopupCondition';
import popupMobileCombo from '@/components/PopupMobileCombo';
import popupUnderlyingDisease from '@/components/PopupUnderlyingDisease';
import popupConfirmedTransferred from '@/components/PopupConfirmedTransferred.vue';
import popupSeverity from '@/components/PopupSeverity.vue';
import popupConfirmedExit from '@/components/PopupConfirmedExit.vue';

import Paginate from 'vuejs-paginate'

export default {
    name: 'ConfirmedListMobile',
    data() {
        return {
            list_symptom_state:[],
            list_fasility:[],
            list_fasility_popup : [],           // 시설별 리스트
            list_move_reason : [],             // 배정상태 리스트
            sch_keyword:'',
            page:1,
            totalPage:0,
            totalCount:0,
            dataList:[],
            statusData:{}
        }
    },
    components: { popupCondition, popupMobileCombo, popupUnderlyingDisease, popupConfirmedTransferred, popupSeverity, Paginate, popupConfirmedExit },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
    },
    methods: {
        init(){
            let formData = new FormData();
            formData.append('api_name', 'patientCreateCode');
            this.ajaxPost('', formData,'initResult');
        },
        initResult(response){
            let result = response.result_data;

            // 미배정사유별
            this.list_symptom_state = result.list_symptom_state;

            // this.list_fasility = result.list_fasility;
            // this.list_fasility.unshift({ code:'', name:'선택' });

            // 시설별, 시설별 팝업
            let vm = this;
            result.list_fasility.forEach(function(obj){
                vm.list_fasility.push(obj)
                vm.list_fasility_popup.push(obj)
            });
            this.list_fasility.unshift({ code:'', name:'선택' });
            this.list_fasility_popup.unshift({ code:'', name:'선택' });

            // 전원사유
            this.list_move_reason = result.list_move_reason;
            this.list_move_reason.unshift({ code:'', name:'선택' });

            this.searchFunc();
        },
        searchFunc(){
            this.searchListFunc(1)
        },
        // 리스트 검색
        searchListFunc(pageIndex){

            let formData = new FormData();
            formData.append('api_name', 'patientMobileList');         // API 이름
            formData.append('sch_keyword', this.sch_keyword);	        // 키워드
            formData.append('page_num', pageIndex);	                // 현재 페이지 번호
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
        },
        searchListFuncResult(response) {
            let result = response.result_data;
            this.dataList = result.list_data;
            this.statusData = result.status_data;
            if (result.total_cnt == 0){
                this.totalPage = 0;
                this.totalCount=0;
            }else{
                this.totalPage = parseInt(result.total_page);
                this.totalCount = parseInt(result.total_cnt);
            }
        },
        // 페이지 변경
        changePage(){
            this.searchListFunc(this.page)
        },
        // 입실처리
        confirmedEnterFunc(obj){
            let msg = obj.f_patient_name +'(' + obj.f_patient_age + ') / ' + ((obj.f_patient_birth != '0000-00-00 00:00:00') ? this.$moment(obj.f_patient_birth).format('YYYY-MM-DD') : '') + ' ( '+ obj.f_patient_sex + ') <br /><br />입실 처리하시겠습니까?';

            let formData = new FormData();
            formData.append('api_name', 'patientStateChange');  // API 이름
            formData.append('f_idx', obj.f_idx);	                    // 확진자 기본키
            formData.append('f_chang_code', 'G0904');	        // 상태변경 분류값
            formData.append('f_admin_id', this.userDataAdminId);

            this.showConfirm("", msg, "commonConfirm", formData);
        },
        // 퇴실처리
        confirmedExitFunc(obj){
            this.$refs.popupConfirmedExit.init(true, obj, this.page)
            // let msg = obj.f_patient_name +'(' + obj.f_patient_age + ') / ' + ((obj.f_patient_birth != '0000-00-00 00:00:00') ? this.$moment(obj.f_patient_birth).format('YYYY-MM-DD') : '') + ' ( '+ obj.f_patient_sex + ') <br /><br />퇴원/퇴소 처리하시겠습니까?';
            //
            // let formData = new FormData();
            // formData.append('api_name', 'patientStateChange');  // API 이름
            // formData.append('f_idx', obj.f_idx);	                    // 확진자 기본키
            // formData.append('f_chang_code', 'G0907');	        // 상태변경 분류값
            // formData.append('f_admin_id', this.userDataAdminId);
            //
            // this.showConfirm("", msg, "commonConfirm", formData);
        },
        // 삭제 처리
        commonConfirm(formData){
            this.ajaxPost('', formData,'commonConfirmResult');
        },
        // 삭제 처리 결과
        commonConfirmResult(response) {
            this.showAlert('', response.result_msg, "searchListFunc", 1);
        },
        // 병상현황 팝업
        popupConditionFunc(){
            this.$refs.popupCondition.init(true, this.statusData, this.page)
        },
        // 병상현황 팝업
        popupMobileFunc(obj, list, mode){
            this.$refs.popupMobileCombo.init(true, obj, list, mode, this.page)
        },
        // 기절질환 등록 팝업
        popupUnderlyingDiseaseFunc(obj){
            this.$refs.popupUnderlyingDisease.init(true, obj, this.page)
        },
        // 전원등록
        popupConfirmedTransferredFunc(obj){
            this.$refs.popupConfirmedTransferred.init(true, obj, this.list_fasility_popup, this.list_move_reason, this.page)
        },
        // 중증도 변경
        popupSeverityFunc(obj){
            this.$refs.popupSeverity.init(true, obj, this.list_symptom_state, this.page)
        },
    }
}
</script>

<style lang="scss">
@import '../../assets/css/mobile.css';
</style>
